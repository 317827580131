<template>
	<div>
		<el-dialog title="发送短信/邮件通知" :visible.sync="showDialog" width="90%" :close-on-click-modal="false"
			custom-class="width_800 cus_dialog" @close="closeDialog">

			<div class="h_100 flexCloumn">
				<el-row style=" margin-bottom: 10px">
					<el-input placeholder="请输入关键字" v-model="nformData.keyword" class="input-with-select"
						style="width: 280px;" size="small">
						<el-select v-model="nformData.ktype" slot="prepend" placeholder="请选择" style="width: 120px;">

							<el-option label="学生姓名" value="stu_name"></el-option>
						</el-select>
					</el-input>

					<el-select v-model="nformData.class_name" placeholder="请选择班级" size="small"
						style="width: 120px; margin-left: 10px;">
						<el-option label="全部班级" value=""></el-option>
						<el-option v-for="(y, i) in ClassList" :label="y.jcname + '-' + y.class_name"
							:value="y.id"></el-option>
					</el-select>
					<el-select v-model="nformData.joined" placeholder="请选择" size="small"
						style="width: 150px; margin-left: 10px;">
						<el-option label="全部参与状态" value=""></el-option>
						<el-option label="未参与" :value="0"></el-option>
						<el-option label="已参与" :value="1"></el-option>

					</el-select>
					<el-button size="small" icon="el-icon-search" style="margin-left: 10px;" @click="noticeSearch" plain
						type="primary">查询</el-button>
					<el-button size="small" icon="el-icon-refresh" @click="noticeReset" type="warning">重置</el-button>


					<el-button size="small" icon="el-icon-bell" @click="reNotify(1)"
						type="warning">给未参与学生发短信和邮件</el-button>

					<el-button size="small" icon="el-icon-download" @click="downXls" type="infor">下载未参与名单</el-button>
				</el-row>
				<el-table stripe :data="noticedataList" height="100%" size="small" stripe border
					style="width:fit-content;width:100%;">
					<el-table-column type="index" label="序号" width="80"></el-table-column>
					<el-table-column prop="stu_code" label="学号" min-width="80"></el-table-column>
					<el-table-column prop="stu_name" label="姓名" min-width="80"></el-table-column>
					<el-table-column prop="jc_name" label="届次" min-width="150"></el-table-column>

					<el-table-column prop="class_name" label="班级" min-width="80"></el-table-column>
					<el-table-column prop="phone" label="手机" min-width="100">
						<template slot-scope="scope">
							<span style="color:#1289ff;">{{ (scope.row.phone || scope.row.sch_phone) }}</span>

						</template>
					</el-table-column>
					<el-table-column prop="n" label="短信发送次数" min-width="100">
					</el-table-column>


					<el-table-column prop="email" label="是否参与" min-width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.joined" style="color: green;" @click="viewInfo(scope.row)">已参与</span>
							<span v-else style="color: orangered;">未参与</span>

						</template>
					</el-table-column>




				</el-table>

				<el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
					:current-page="page.current_page" :page-size="page.per_page" :total="page.count"
					layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
				</el-pagination>
			</div>
		</el-dialog>


		<el-dialog title="作答详情" :visible.sync="showWenjuan" width="800px" custom-class="width_800 cus_dialog" top="5%">
			<h5result :id="joinedID" :ivstid="ivstId" v-if="showWenjuan"></h5result>
		</el-dialog>

	</div>
</template>

<script>
import h5result from "./h5result.vue"
export default {
	props: ["plan"],
	components: { h5result },
	data() {
		return {
			showDialog: true,
			showDialog_notice: false,
			ClassList: [],
			nformData: {
				gyear: "",
				class_name: "",
				ktype: "stu_name",
				keyword: "",
				plan_id: 0,
				joined: ""
			},
			noticedataList: [],
			YearList: [],
			showWenjuan: false,
			joinedID: 0,
      ivstId: 0,
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
			},
		}
	},
	mounted() {
		console.log('this.plan')

		this.nformData.plan_id = this.plan.id
		this.getClass()
		this.noticeSearch()

	},
	methods: {

		showNotify(scope) {
			var self = this;
			self.showDialog_notice = true;
		},

		getClass() {
			this.$http.post("/api/get_plan_classes", {
				plan_id: this.plan.id
			}).then(res => {
				this.ClassList = res.data
			})
		},
		handlePageChange(page) {
			this.page.current_page = page
			this.noticeSearch()
		},
		handleSizeChange(val) {
			this.page.current_page = 1;
			this.page.per_page = val;
			this.noticeSearch()
		},

		noticeSearch() {

			this.$http.post("/api/by_ivst_sms_sendlog2", {
				plan_id: this.plan.id,
				keyword: this.nformData.keyword,
				joined: this.nformData.joined,
				class_id: this.nformData.class_name,
				page: this.page.current_page
			}).then((res) => {

				this.noticedataList = res.data.data;
				this.page = res.data.page;

			});
		},
		noticeReset() {
			this.nformData = {};
			// this.getlist();
		},
		closeDialog() {
			this.$parent.showSms = false
		},
		reNotify(t) {
			this.$confirm('短信和邮件发送较慢，请勿多次点击发送按钮，否则将发送多条！', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
				this.$http
					.post("/api/bygz_start_send_notify", {plan_id: this.plan.id})
				.then((res) => {

					this.$message.success("后台正在发送中，切勿多次点击发送按钮！！！")

				});

            }).catch(() => {

            });
			
		},
		viewInfo(row) {
			this.joinedID = row.joined
      this.ivstId = row.ivst_id
			this.showWenjuan = true
		},
		sendSmsOne(row) {
			this.$http
				.post("/api/send_one_notify", { plan_id: this.nformData.plan_id, stu_id: row.stu_id })
				.then((res) => {

					this.$message.success("发送成功")

				});
		},
		downXls(){
			this.$http
				.post("/api/by_download_notjoin", { plan_id: this.nformData.plan_id })
				.then((res) => {

					if(res.data.url){
						window.open(res.data.url,"_blank")
					}

				});
		}
	},
}
</script>